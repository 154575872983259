import { createContext, useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { includes } from 'ramda';

import { useAuth } from '@clerk/nextjs';

import { useUserAuth } from '@/context/user-auth-provider';

import { Item } from '@@/ItemAndGrading/entities/item';
import { useFindItem } from '@@/ItemAndGrading/hooks/use-item-service';
import { useBestBid } from '@@/Pricing/hooks/use-bid-service';
import { BestBidResponse } from '@@/Pricing/services/bid-service';
import { TradeIn } from '@@/TradeIn/entities/trade-in';
import { useFindTradeIn } from '@@/TradeIn/hooks/use-trade-in-service';

import { STORAGE_KEYS, useLocalStorage } from '@/hooks/use-local-storage';

import { PUBLIC_ROUTES } from '@/constants';

import { isEmptyOrNil } from '@/utils';

import FullScreenSpinner from '@/components/full-screen-spinner';

export type TradeInContext = {
  tradeInData: TradeIn;
  setTradeInData: (data: TradeIn) => void;
  itemId: string;
  itemData: Item;
  setItemData: (data: Item) => void;
  customerRefId: string;
  setCustomerRefId: (refId: string) => void;
  bestBidData: BestBidResponse;
  setBestBidData: (data: BestBidResponse) => void;
  isBestBidLoading?: boolean;
  isBestBidWithError?: boolean;
  modelId: string;
  productName: string;
  cleanUp: () => void;
};

const tradeInContext = createContext<TradeInContext>({} as TradeInContext);

export const TradeInProvider: React.FCWC = ({ children }) => {
  const storage = useLocalStorage();
  const { interceptorId, storeData, isSignedIn } = useUserAuth();
  const { isLoaded } = useAuth();
  const { query, pathname } = useRouter();

  const isAuthLoaded = isLoaded && isSignedIn && interceptorId !== null;
  const isNotPublicRoute = !includes(pathname, PUBLIC_ROUTES);

  const tradeInId = storage?.hasItem(STORAGE_KEYS.DOJI_TRADE_IN_ID)
    ? storage?.getItem(STORAGE_KEYS.DOJI_TRADE_IN_ID)
    : '';
  const itemId = storage?.hasItem(STORAGE_KEYS.DOJI_ITEM_ID) && storage?.getItem(STORAGE_KEYS.DOJI_ITEM_ID);
  const storageModelId = storage?.hasItem(STORAGE_KEYS.DOJI_MODEL_ID) && storage?.getItem(STORAGE_KEYS.DOJI_MODEL_ID);
  const storageCustomerRefId = storage?.hasItem(STORAGE_KEYS.DOJI_CUSTOMER_REF_ID)
    ? storage?.getItem(STORAGE_KEYS.DOJI_CUSTOMER_REF_ID)
    : query?.refId;

  const [customerRefId, setCustomerRefId] = useState(storageCustomerRefId ?? undefined);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const tradeInResponse = useFindTradeIn({
    tradeInId: tradeInId,
    enabled: !!tradeInId && isAuthLoaded && !customerRefId,
  });

  const itemResponse = useFindItem({
    itemId: itemId,
    enabled: !!itemId && isAuthLoaded && !customerRefId,
  });

  const bestBidResponse = useBestBid({
    retry: 2,
    itemId: itemId,
    storeId: storeData?.id,
    buyProductPrice: storage?.getItem(STORAGE_KEYS.DOJI_BUY_PRODUCT_PRICE) ?? null,
    isCustomerTradeIn: !!customerRefId,
    enabled: !!itemId && Boolean(customerRefId || (!customerRefId && isAuthLoaded)),
  });

  const [tradeInData, setTradeInData] = useState<TradeIn>(tradeInResponse?.tradeInData as TradeIn);
  const [itemData, setItemData] = useState<Item>(itemResponse?.itemData as Item);
  const [bestBidData, setBestBidData] = useState(bestBidResponse.bestBidData ?? ({} as BestBidResponse));

  useEffect(() => {
    if (customerRefId) storage?.setItem(STORAGE_KEYS.DOJI_CUSTOMER_REF_ID, customerRefId);
  }, [customerRefId]);

  useEffect(() => {
    if (itemData || itemResponse?.itemData) {
      const item = itemData || itemResponse?.itemData;
      if (isEmptyOrNil(item)) return;
      setItemData(item);
      storage?.setItem(STORAGE_KEYS.DOJI_ITEM_ID, item.id);
      if (item?.product?.model?.fullModelName)
        storage?.setItem(STORAGE_KEYS.DOJI_PRODUCT_NAME, item.product.model.fullModelName);
    }
  }, [itemResponse?.itemData, itemData]);

  useEffect(() => {
    if (!isEmptyOrNil(tradeInData) || !isEmptyOrNil(tradeInResponse?.tradeInData)) {
      const tradeIn = tradeInData || tradeInResponse?.tradeInData;
      setTradeInData(tradeIn);
      storage?.setItem(STORAGE_KEYS.DOJI_TRADE_IN_ID, tradeIn.id);
      storage?.setItem(STORAGE_KEYS.DOJI_ITEM_ID, tradeIn.itemId);
    }
  }, [tradeInData, tradeInResponse?.tradeInData]);

  useEffect(() => {
    if (bestBidResponse.isBestBidSuccess && bestBidResponse.bestBidData) {
      setBestBidData(bestBidResponse.bestBidData);
    }
  }, [bestBidResponse.isBestBidSuccess, bestBidResponse.bestBidData]);

  useEffect(() => {
    if (customerRefId) setIsDataLoaded(true);
    else if (isEmptyOrNil(tradeInId)) setIsDataLoaded(true);
    else if (!isEmptyOrNil(tradeInData) && !isEmptyOrNil(itemData) && !isEmptyOrNil(bestBidData)) {
      setIsDataLoaded(true);
    }
  }, [tradeInId, tradeInData, itemData, bestBidData]);

  const cleanUp = () => {
    setItemData({} as Item);
    setTradeInData({} as TradeIn);
    setBestBidData({} as BestBidResponse);
    storage?.removeItem(STORAGE_KEYS.DOJI_TRADE_IN_ID);
    storage?.removeItem(STORAGE_KEYS.DOJI_ITEM_ID);
    storage?.removeItem(STORAGE_KEYS.DOJI_MODEL_ID);
    storage?.removeItem(STORAGE_KEYS.DOJI_SURVEY_ANSWERS);
    storage?.removeItem(STORAGE_KEYS.DOJI_BUY_PRODUCT_PRICE);
    storage?.removeItem(STORAGE_KEYS.DOJI_CUSTOMER_FORMS);
    storage?.removeItem(STORAGE_KEYS.DOJI_PRODUCT_NAME);
  };

  const value = {
    tradeInData,
    setTradeInData,
    itemData,
    setItemData,
    modelId: storageModelId,
    cleanUp,
    customerRefId,
    setCustomerRefId,
    bestBidData,
    setBestBidData,
    itemId: itemData?.id ?? itemId,
    productName:
      itemData?.product?.model?.fullModelName ?? storage?.getItem(STORAGE_KEYS.DOJI_PRODUCT_NAME) ?? query?.product,
    isBestBidLoading: bestBidResponse.isBestBidLoading,
    isBestBidWithError: bestBidResponse.isBestBidError,
  };

  if ((!isAuthLoaded && isNotPublicRoute) || (isNotPublicRoute && !isDataLoaded)) return <FullScreenSpinner />;

  return <tradeInContext.Provider value={value}>{children}</tradeInContext.Provider>;
};

export function useTradeInContext() {
  return useContext(tradeInContext);
}
